import * as React from 'react';
import { Typography } from 'Atoms';
import { LoopIcon } from '../PreferencesPage.styles';

interface HeadingProps {
  heading: string;
}

export const Heading: React.FC<HeadingProps> = ({ heading }: HeadingProps) => {
  return (
    <Typography variant="h1">
      <LoopIcon width={29} height={29} />
      {heading}
    </Typography>
  );
};
