import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Typography } from 'Atoms';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 1rem;
    border-radius: 0.1875rem;
    background-color: ${theme.colorMappings.specialCategoryQuestionsBackground};
    border: 0.0625rem solid
      ${darken(0.1, theme.colorMappings.specialCategoryQuestionsBackground)};
  `}
`;
export const WithPadding = styled.div`
  padding: 2.375rem 1.25rem;
  padding-bottom: 0;
`;

export const QuestionsWrapper = styled.div`
  padding: 0 1.25rem 1.5625rem;
  background-color: ${({ theme }) =>
    theme.colorMappings.specialCategoryQuestionsBackground};
`;

export const SpecialCategoryTitle = styled(Typography)`
  font-size: 1.625em;
  font-weight: 600;
  padding-bottom: 1.5rem;
`;
