import * as React from 'react';
import { HtmlDescription } from 'Molecules';
import { SpecialCategoryQuestionsContainerProps } from './types';
import {
  Wrapper,
  WithPadding,
  QuestionsWrapper,
  SpecialCategoryTitle,
} from './SpecialCategoryQuestionsContainer.styles';
import { DemographicsQuestions } from './';

export const SpecialCategoryQuestionsContainer: React.FC<
  SpecialCategoryQuestionsContainerProps
> = ({
  title,
  description,
  questions,
}: SpecialCategoryQuestionsContainerProps) => {
  return (
    <Wrapper>
      <WithPadding>
        <SpecialCategoryTitle variant="h3" gutterBottom>
          {title}
        </SpecialCategoryTitle>
        <HtmlDescription typographyProps={{ variant: 'body1' }}>
          {description}
        </HtmlDescription>
      </WithPadding>
      <QuestionsWrapper>
        <DemographicsQuestions questions={questions} />
      </QuestionsWrapper>
    </Wrapper>
  );
};
