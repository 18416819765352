import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 1rem;

  label {
    text-align: ${({ theme }) =>
      theme.direction === 'rtl' ? 'right' : 'left'};
    margin: 0.5rem 0;
    padding: 0;
  }

  div[class*='QuestionSecondaryText'] {
    text-align: ${({ theme }) =>
      theme.direction === 'rtl' ? 'right' : 'left'};
    margin-bottom: 0.8125rem;
  }
`;
