import * as React from 'react';
import { Typography } from 'Atoms';
import { ConsentsOption } from './ConsentsOption';
import { ConsentsDescription } from './ConsentsContainer.styles';
import { ConsentsContainerProps } from '../types';

export const ConsentsContainer: React.FC<ConsentsContainerProps> = ({
  consentsGroup: { title, description, helperText, consents },
  onConsentsToggle,
}) => {
  const orderedConsents = [...consents].sort((a, b) => a.order - b.order);

  return (
    <div style={{ marginTop: 3 }} data-testid="consents-container-parent">
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      {[description, helperText].map(
        (text) =>
          text && <ConsentsDescription key={text}>{text}</ConsentsDescription>
      )}
      {orderedConsents.map((c) => (
        <ConsentsOption
          key={c.order}
          {...c}
          onConsentsToggle={onConsentsToggle}
        />
      ))}
    </div>
  );
};
