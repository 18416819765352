import * as React from 'react';
import Box from '@material-ui/core/Box';
import { DemographicsQuestionsProps } from './types';
import { DemographicsQuestions } from '.';

export const QuestionsContainer: React.FC<DemographicsQuestionsProps> = ({
  questions,
}: DemographicsQuestionsProps) => {
  return (
    <Box mt={1.5}>
      <div>
        <DemographicsQuestions questions={questions} />
      </div>
    </Box>
  );
};
