import { makeRedirectDomain } from 'Client/utils/url';

export const insertProjectNames = (text: string, projectName): string => {
  if (!text) return '';
  // 'Template default',
  // 'Template - Active travel',
  // 'Template - Residential Development',
  // 'Template - Parks and Green Spaces',
  // '{{projectName}}',

  const regex =
    /Template default|Template - Active travel|Template - Residential Development|Template - Parks and Green Spaces|{{projectName}}/g;

  const newText = text;
  return newText?.split(regex).join(projectName);
};

export const insertProjectTeamLink = (
  text: string,
  projectName: string
): string => {
  if (!text) return '';
  const regex =
    /http:\/\/templatedefault.commonplace.is\/project-team|http:\/\/templateactivetravel.commonplace.is\/project-team|http:\/\/templateresidentialdevelopment.commonplace.is\/project-team|http:\/\/http:\/\/templateparksandgreenspaces.commonplace.is\/project-team|{{teamPageLink}}/g;

  const newText = text;
  const linkToReplace = `http://${makeRedirectDomain(
    projectName
  )}/project-team`;

  return newText?.split(regex).join(linkToReplace);
};
