import styled, { css } from 'styled-components';
import { LoopIcon as LoopIconBase } from 'Icons';
import { HtmlDescription } from 'Molecules';

export const Wrapper = styled.div`
  padding: 2rem 0 ${({ theme }) => theme.proposals.footer.height};
`;

export const IntroDescription = styled(HtmlDescription)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const LoopIcon = styled(LoopIconBase)`
  ${({ theme }) => css`
    margin-${theme.direction === 'rtl' ? 'left' : 'right'}: 0.5rem;
    color: ${theme.colorMappings.CommonplaceBrand};
  `}
`;

export const FooterTextWrapper = styled.div`
  margin-top: 2rem;
`;
