import * as React from 'react';
import { FieldInfoTip, FormControl, InfoTipIconTypes } from 'Atoms';
import {
  PostcodeField,
  TextArea,
  TextField,
  TextPoll,
  SelectField,
  PhoneNumberField,
} from 'Molecules';
import { AddressField, PriorityList } from 'Organisms';
import { SENSITIVITY_TYPES } from 'Constants/demographics';
import { OptionItem } from 'Client/types';
import { LabelValueOptions } from 'Shared/types/question';
import { DemographicsQuestionRendererProps } from './types';
import { Wrapper } from './DemographicsQuestionRenderer.styles';

const DemographicsQuestionRenderer: React.FC<
  DemographicsQuestionRendererProps
> = ({
  type,
  category,
  question,
  infoTip,
  ...props
}: DemographicsQuestionRendererProps) => {
  const InfoTip = ({ infoTip }: { infoTip?: string }) => {
    return infoTip ? (
      <FieldInfoTip infoTip={infoTip} infoTipIcon={InfoTipIconTypes.INFO} />
    ) : null;
  };
  const getQuestionTypeContent = (type) => {
    switch (type) {
      case 'text':
        if (category === SENSITIVITY_TYPES.POSTCODE) {
          return (
            <PostcodeField
              {...question}
              type={'text'}
              infoTip={infoTip}
              infoTipIcon={InfoTipIconTypes.INFO}
              {...props}
            />
          );
        }
        return (
          <TextField
            {...question}
            type={'text'}
            showLabel
            width="100%"
            infoTip={infoTip}
            infoTipIcon={InfoTipIconTypes.INFO}
            {...props}
          />
        );
      case 'textarea':
        return (
          <TextArea
            {...question}
            {...props}
            infoTip={infoTip}
            infoTipIcon={InfoTipIconTypes.INFO}
          />
        );
      case 'textpoll':
        return (
          <>
            {' '}
            <TextPoll
              {...question}
              options={question.options as Array<LabelValueOptions>}
              currentSelections={question.selected} // currently null, when we init with user demographics should be populated
              {...props}
            />
            <InfoTip infoTip={infoTip} />
          </>
        );
      case 'label':
        return (
          <>
            <TextPoll
              {...question}
              options={question.values as Array<LabelValueOptions>}
              currentSelections={question.selected} // currently null, when we init with user demographics should be populated
              selectMultiple={true}
              {...props}
            />
            <InfoTip infoTip={infoTip} />
          </>
        );
      case 'select':
        return (
          <>
            <SelectField
              {...question}
              options={question.options as Array<OptionItem>}
              {...props}
            />
            <InfoTip infoTip={infoTip} />
          </>
        );
      case 'priorities': {
        const items = question?.selected ?? question?.options ?? [];
        return (
          <>
            <FormControl>
              <PriorityList
                key={JSON.stringify(question)}
                label={question.label}
                items={(items as Array<string>).map((v) => ({ content: v }))}
                onChange={props.handleChange}
              />
            </FormControl>
            <InfoTip infoTip={infoTip} />
          </>
        );
      }
      case 'address':
        return (
          <>
            <AddressField {...question} {...props} />
            <InfoTip infoTip={infoTip} />
          </>
        );

      case 'phone-number':
        return (
          <>
            <PhoneNumberField
              {...question}
              {...props}
              type={'tel'}
              showLabel
              width="100%"
            />
            <InfoTip infoTip={infoTip} />
          </>
        );
      default:
        return (
          <div
            data-scroll-id={question.id}
          >{`Unknown demographics question type: ${type}`}</div>
        );
    }
  };
  return (
    <Wrapper
      data-scroll-id={question.id}
      data-testid="DemographicsSectionRender"
    >
      {getQuestionTypeContent(type)}
    </Wrapper>
  );
};

export { DemographicsQuestionRenderer };
