import * as React from 'react';
import { Typography } from 'Atoms';
import { CheckboxChecked, CheckboxUnchecked, MappedIcon } from 'Icons';
import {
  OptionCheckbox,
  OptionContainer,
  OptionContent,
  OptionHeader,
} from './ConsentsOption.styles';
import { Consent, ConsentsOption as ConsentsOptionType } from '../types';

interface ConsentsOptionProps extends ConsentsOptionType {
  onConsentsToggle: (consent: Consent) => void;
}

const ConsentsOption: React.FC<ConsentsOptionProps> = ({
  enabled,
  icon,
  type,
  heading,
  description,
  programme,
  onConsentsToggle,
  _id,
}: ConsentsOptionProps) => {
  const [checked, setChecked] = React.useState<boolean>(enabled);
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [focused, setFocused] = React.useState<boolean>(false);

  const active = checked || hovered;

  return (
    <>
      <OptionContainer
        active={active}
        className={focused && 'hasFocus'}
        onClick={() => {
          const enabled = !checked;
          setChecked(enabled);
          const consent: Consent = {
            heading,
            description,
            type,
            enabled,
            programme,
          };
          onConsentsToggle(consent);
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        data-testid="consents-option-container"
        data-scroll-id={_id}
      >
        <OptionCheckbox
          active={active}
          checked={checked}
          icon={<CheckboxUnchecked />}
          checkedIcon={<CheckboxChecked />}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <OptionContent>
          <OptionHeader active={active}>
            <MappedIcon icon={icon} noContainer />
            <Typography bold variant="body1">
              {heading}
            </Typography>
          </OptionHeader>
          <Typography>{description}</Typography>
        </OptionContent>
      </OptionContainer>
    </>
  );
};

export { ConsentsOption };
