export const CONTRIBUTIONS_LAYER = 'Contributions';

export enum FEATURE_TYPES {
  POINT = 'Point',
  CIRCLE = 'Circle',
  POLYGON = 'Polygon',
  LINE = 'Line',
  LINESTRING = 'LineString',
  FEATURE = 'Feature',
}

export const PIN_SVG_ICON =
  'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTUwIDAgNjAwIDUwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4Ij4KICAgIDxwYXRoIGQ9Ik0yNTYsMEMxNTMuNzU1LDAsNzAuNTczLDgzLjE4Miw3MC41NzMsMTg1LjQyNmMwLDEyNi44ODgsMTY1LjkzOSwzMTMuMTY3LDE3My4wMDQsMzIxLjAzNSYjMTA7ICAgICAgYzYuNjM2LDcuMzkxLDE4LjIyMiw3LjM3OCwyNC44NDYsMGM3LjA2NS03Ljg2OCwxNzMuMDA0LTE5NC4xNDcsMTczLjAwNC0zMjEuMDM1QzQ0MS40MjUsODMuMTgyLDM1OC4yNDQsMCwyNTYsMHogTTI1NiwyNzguNzE5JiMxMDsgICAgICBjLTUxLjQ0MiwwLTkzLjI5Mi00MS44NTEtOTMuMjkyLTkzLjI5M1MyMDQuNTU5LDkyLjEzNCwyNTYsOTIuMTM0czkzLjI5MSw0MS44NTEsOTMuMjkxLDkzLjI5M1MzMDcuNDQxLDI3OC43MTksMjU2LDI3OC43MTl6IiBzdHlsZT0iZmlsdGVyOiBkcm9wLXNoYWRvdygwIDAgMi43NXJlbSAjYTNhM2EzKSIgZmlsbD0iIzg0MjU4RCIvPjwvc3ZnPg==';

export const CURSOR_PIN_SVG_ICON =
  'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTUwIDAgNjAwIDUwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMzZweCIgaGVpZ2h0PSIzNnB4Ij4KICAgIDxwYXRoIGQ9Ik0yNTYsMEMxNTMuNzU1LDAsNzAuNTczLDgzLjE4Miw3MC41NzMsMTg1LjQyNmMwLDEyNi44ODgsMTY1LjkzOSwzMTMuMTY3LDE3My4wMDQsMzIxLjAzNSYjMTA7ICAgICAgYzYuNjM2LDcuMzkxLDE4LjIyMiw3LjM3OCwyNC44NDYsMGM3LjA2NS03Ljg2OCwxNzMuMDA0LTE5NC4xNDcsMTczLjAwNC0zMjEuMDM1QzQ0MS40MjUsODMuMTgyLDM1OC4yNDQsMCwyNTYsMHogTTI1NiwyNzguNzE5JiMxMDsgICAgICBjLTUxLjQ0MiwwLTkzLjI5Mi00MS44NTEtOTMuMjkyLTkzLjI5M1MyMDQuNTU5LDkyLjEzNCwyNTYsOTIuMTM0czkzLjI5MSw0MS44NTEsOTMuMjkxLDkzLjI5M1MzMDcuNDQxLDI3OC43MTksMjU2LDI3OC43MTl6IiBzdHlsZT0iZmlsdGVyOiBkcm9wLXNoYWRvdygwIDAgMi43NXJlbSAjYTNhM2EzKSIgZmlsbD0iIzg0MjU4RCIvPjwvc3ZnPg==';
