import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, StakeholderCard } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import { useProject } from 'Client/utils/hooks';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StakeholdersWrapper,
  StakeholderCardContainer,
  FooterWrapper,
} from './MeetTheTeamPanel.styles';
import { MeetTheTeamPanelProps } from './types';
import { insertProjectTeamLink } from '../utils/insertProjectsNames';

export const MeetTheTeamPanel: React.FC<MeetTheTeamPanelProps> = ({
  intro,
  footer,
  stakeholders,
}: MeetTheTeamPanelProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const project = useProject();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [expanded, setExpanded] = React.useState<boolean>(
    isMobile ? false : true
  );

  const handleChangeExpand = (_ev: React.ChangeEvent, exp: boolean) => {
    setExpanded(exp);
  };

  const treatedIntro = insertProjectTeamLink(intro, project.id);

  return (
    <Box my={1}>
      <Accordion expanded={expanded} onChange={handleChangeExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="meet-the-team-content"
          id="meet-the-team-header"
        >
          <Typography variant="h3">{t('Meet the team')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={1} mt={1}>
            <HtmlDescription typographyProps={{ variant: 'body1' }}>
              {treatedIntro}
            </HtmlDescription>
          </Box>
          <StakeholdersWrapper container>
            {stakeholders?.map((stakeholder) => (
              <StakeholderCardContainer
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                key={stakeholder.name}
                data-testid="stakeholder-card-container"
              >
                <StakeholderCard
                  action
                  stakeholder={{
                    ...stakeholder,
                    role: undefined,
                    responsibilities: undefined,
                  }}
                />
              </StakeholderCardContainer>
            ))}
          </StakeholdersWrapper>
          <FooterWrapper>
            <Box mb={1} mt={1}>
              <HtmlDescription typographyProps={{ variant: 'body1' }}>
                {footer}
              </HtmlDescription>
            </Box>
          </FooterWrapper>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
