import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = styled(MuiAccordion)`
  background-color: ${({ theme }) => theme.colors.grey[100]};
  border-radius: 0.2rem;

  > div:first-child {
    display: flex;
    padding: 0 2rem;

    > div:last-child {
      padding: 0.75rem;
    }
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  justify-content: flex-start;

  > div {
    flex-grow: unset;
  }

  .MuiSvgIcon-root {
    color: black;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0 1rem;
    }
  `}
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  display: inline-block;
  padding-top: 0;

  > div {
    margin: 0;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0 1rem 2rem;
    }
  `}
`;

export const StakeholdersWrapper = styled(Grid)`
  margin: 0 -0.5rem;
`;

export const StakeholderCardContainer = styled(Grid)`
  margin: 0.5rem;
  min-width: 7rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      max-width: 7rem;
    }
  `}
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  img {
  }
`;
